import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { isValidJson } from '../../app/helpers/utils';
import dayjs from 'dayjs';
import {
  tvAddFavoriteChannel,
  tvFetchChannelCatchup,
  tvFetchChannelEpg,
  tvFetchChannelsGrouped,
  tvFetchChannelsStream,
  tvFetchFavoriteChannels,
  tvRemoveFavoriteChannel,
  tvFetchChannelInfoById
} from '../../api/channelsApi';

import { FETCH_STATUS_FAILED, FETCH_STATUS_LOADING, FETCH_STATUS_SUCCESS } from '../constants';

const initialState = {
  channelId: null,
  channelsGrouped: {
    channelsGroupedList: [],
    status: 'idle',
    error: null
  },
  stream: {
    src: null,
    status: 'idle',
    error: null,
    isArchive: false,
    data: null,
    statusCode: null
  },
  currentChannelInfo: {
    channelInfo: {
      id: '',
      genre_id: '',
      logo: '',
      name: '',
      briz_billing_product_info: {
        in_favorites: false,
        is_available: false,
        is_free: false
      }
    },
    status: 'idle',
    error: null
  },
  channelsFavorites: {
    channelsFavoritesList: [],
    status: 'idle',
    error: null
  },
  channelToFavoriteStatus: 'idle',
  channelEpg: {
    channelEpgDataCurrentVideo: {
      from: dayjs().startOf('day').unix(),
      to: dayjs().endOf('day').unix(),
      channelId: null,
      channelEpgListCurrentVideo: []
    },
    channelEpgDataView: {
      from: dayjs().startOf('day').unix(),
      to: dayjs().endOf('day').unix(),
      channelId: null,
      channelEpgListView: [],
      status: 'idle'
    },
    channelEpgCatchup: {
      name: '',
      from: null,
      to: null,
      id: null
    },
    forwardEpgDisallowed: false,
    backwardEpgDisallowed: false,
    status: 'idle',
    error: null
  }
};

export const fetchChannelsStream = createAsyncThunk(
  'channels/fetchChannelsStream',
  async ({ channelId }, thunkAPI) => {
    return await tvFetchChannelsStream(channelId, {
      signal: thunkAPI.signal
    });
  }
);

export const fetchChannelInfoById = createAsyncThunk(
  'channels/fetchChannelInfoById',
  async (channel_id) => {
    return await tvFetchChannelInfoById(channel_id);
  }
);

export const fetchChannelCatchup = createAsyncThunk(
  'channels/fetchChannelCatchup',
  async ({ channelId, from, to }) => {
    return await tvFetchChannelCatchup(channelId, from, to);
  }
);

export const fetchChannelsGrouped = createAsyncThunk('channels/fetchChannelsGrouped', async () => {
  return await tvFetchChannelsGrouped();
});

export const updatefetchChannelsGrouped = createAsyncThunk(
  'channels/updatefetchChannelsGrouped',
  async () => {
    return await tvFetchChannelsGrouped();
  }
);

export const fetchChannelEpgAll = createAsyncThunk(
  'channels/fetchChannelEpgAll',
  async ({ channelId, from, to }, thunkAPI) => {
    return await tvFetchChannelEpg(channelId, from, to, {
      signal: thunkAPI.signal
    });
  }
);

export const updateChannelEpgView = createAsyncThunk(
  'channels/updateChannelEpgView',
  async ({ channelId, from, to }) => {
    return await tvFetchChannelEpg(channelId, from, to);
  }
);

export const updateChannelEpgCurrentVideo = createAsyncThunk(
  'channels/updateChannelEpgCurrentVideo',
  async ({ channelId, from, to }) => {
    return await tvFetchChannelEpg(channelId, from, to);
  }
);

export const fetchFavoriteChannels = createAsyncThunk(
  'channels/fetchFavoriteChannels',
  async () => {
    return await tvFetchFavoriteChannels();
  }
);

export const updatefetchFavoriteChannels = createAsyncThunk(
  'channels/updatefetchFavoriteChannels',
  async () => {
    return await tvFetchFavoriteChannels();
  }
);

export const updateFavoriteChannels = createAsyncThunk(
  'channels/updateFavoriteChannels',
  async () => {
    return await tvFetchFavoriteChannels();
  }
);

export const addFavoriteChannel = createAsyncThunk(
  'channels/addFavoriteChannel',
  async ({ channelId }) => {
    return await tvAddFavoriteChannel({ channel_id: channelId });
  }
);

export const removeFavoriteChannel = createAsyncThunk(
  'channels/removeFavoriteChannel',
  async ({ channelId }) => {
    return await tvRemoveFavoriteChannel({ channel_id: channelId });
  }
);

const channelsSlice = createSlice({
  name: 'channels',
  initialState,
  reducers: {
    setChannelId: (state, action) => {
      state.channelId = action.payload;
    },
    resetCurentChannelInfo: (state) => {
      state.currentChannelInfo = initialState.currentChannelInfo;
    },
    resetChannelStream: (state) => {
      state.stream = initialState.stream;
    },
    setInitialChannelsGrouped: (state) => {
      state.channelsGrouped = initialState.channelsGrouped;
    },
    setInitialFavoritesChannels: (state) => {
      state.channelsFavorites = initialState.channelsFavorites;
    },
    addToFavoriteChannelList: (state, action) => {
      state.channelsFavorites.channelsFavoritesList.push(action.payload);
      const index = state.channelsGrouped.channelsGroupedList.findIndex(
        (i) => i.genre_id === action.payload.genre_id
      );
      const channelsByIndex = state.channelsGrouped.channelsGroupedList[index]?.channels;
      if (channelsByIndex) {
        const updatedChannels = channelsByIndex.map((i) => {
          if (i.id === action.payload.id) {
            return action.payload;
          }
          return i;
        });
        state.channelsGrouped.channelsGroupedList[index].channels = updatedChannels;
      }
    },
    removeFromFavoriteChannelList: (state, action) => {
      state.channelsFavorites.channelsFavoritesList =
        state.channelsFavorites.channelsFavoritesList.filter((i) => i.id !== action.payload.id);
      const index = state.channelsGrouped.channelsGroupedList.findIndex(
        (i) => i.genre_id === action.payload.genre_id
      );
      const channelsByIndex = state.channelsGrouped.channelsGroupedList[index]?.channels;
      if (channelsByIndex) {
        const updatedChannels = channelsByIndex.map((i) => {
          if (i.id === action.payload.id) {
            return action.payload;
          }
          return i;
        });
        state.channelsGrouped.channelsGroupedList[index].channels = updatedChannels;
      }
    },
    changeCurrentChannelInfoStatusFavorite: (state, action) => {
      state.currentChannelInfo.channelInfo = action.payload;
    },
    updateCurrentActiveChannelsGroup: (state, action) => {
      state.channelsGrouped.channelsGroupedList = state.channelsGrouped.channelsGroupedList.map(
        (i) => ({
          ...i,
          active: i.channels.some((i) => i.id == action.payload)
        })
      );
    }
  },
  extraReducers: {
    [fetchChannelInfoById.pending]: (state) => {
      state.currentChannelInfo.status = FETCH_STATUS_LOADING;
    },
    [fetchChannelInfoById.fulfilled]: (state, action) => {
      state.currentChannelInfo.status = FETCH_STATUS_SUCCESS;
      state.currentChannelInfo.channelInfo = action.payload.data;
    },
    [fetchChannelInfoById.rejected]: (state, action) => {
      state.currentChannelInfo.status = FETCH_STATUS_FAILED;
      state.currentChannelInfo.error = action.error;
    },
    [fetchChannelsStream.pending]: (state) => {
      state.stream.status = FETCH_STATUS_LOADING;
    },
    [fetchChannelsStream.fulfilled]: (state, action) => {
      state.stream.status = FETCH_STATUS_SUCCESS;
      state.stream.src = action.payload.data.src;
      state.channelEpg.channelEpgCatchup = initialState.channelEpg.channelEpgCatchup;
      state.stream.isArchive = false;
    },
    [fetchChannelsStream.rejected]: (state, action) => {
      if (isValidJson(action.error.message)) {
        const errorData = JSON.parse(action.error.message);
        state.stream.error = errorData.error;
        if (Array.isArray(errorData.data)) {
          state.stream.data = errorData.data;
        } else {
          state.stream.data = [errorData.data];
        }
      } else {
        state.stream.error = action.error.message;
      }
      state.stream.status = FETCH_STATUS_FAILED;
      if (action?.error?.message) {
        if (isValidJson(action.error.message)) {
          const parsedData = JSON.parse(action.error.message);
          state.stream.statusCode = parsedData?.statusCode;
        }
      }
    },
    [fetchChannelCatchup.pending]: (state) => {
      state.stream.status = FETCH_STATUS_LOADING;
    },
    [fetchChannelCatchup.fulfilled]: (state, action) => {
      state.stream.status = FETCH_STATUS_SUCCESS;
      state.stream.src = action.payload.data.src;
      state.stream.isArchive = true;
    },
    [fetchChannelCatchup.rejected]: (state, action) => {
      if (isValidJson(action.error.message)) {
        const errorData = JSON.parse(action.error.message);
        state.stream.error = errorData.error;
        if (Array.isArray(errorData.data)) {
          state.stream.data = errorData.data;
        } else {
          state.stream.data = [errorData.data];
        }
      } else {
        state.stream.error = action.error.message;
      }
      state.stream.status = FETCH_STATUS_FAILED;
      if (action?.error?.message) {
        if (isValidJson(action.error.message)) {
          const parsedData = JSON.parse(action?.error?.message);
          state.stream.statusCode = parsedData?.statusCode;
        }
      }
    },
    [fetchChannelsGrouped.pending]: (state) => {
      state.channelsGrouped.status = FETCH_STATUS_LOADING;
    },
    [fetchChannelsGrouped.fulfilled]: (state, action) => {
      state.channelsGrouped.status = FETCH_STATUS_SUCCESS;
      state.channelsGrouped.channelsGroupedList = action.payload.data.map((i) => ({
        ...i,
        active: i.channels.some((i) => i.id == state.channelId)
      }));
    },
    [fetchChannelsGrouped.rejected]: (state, action) => {
      state.channelsGrouped.status = FETCH_STATUS_FAILED;
      state.channelsGrouped.error = action.error;
    },

    [updatefetchChannelsGrouped.fulfilled]: (state, action) => {
      state.channelsGrouped.channelsGroupedList = action.payload.data.map((i) => ({
        ...i,
        active: i.channels.some((i) => i.id == state.channelId)
      }));
    },
    [updatefetchChannelsGrouped.rejected]: (state, action) => {
      state.channelsGrouped.status = FETCH_STATUS_FAILED;
      state.channelsGrouped.error = action.error;
    },
    [fetchChannelEpgAll.pending]: (state) => {
      state.channelEpg.status = FETCH_STATUS_LOADING;
    },
    [fetchChannelEpgAll.fulfilled]: (state, action) => {
      state.channelEpg.status = FETCH_STATUS_SUCCESS;
      state.channelEpg.channelEpgDataCurrentVideo = {
        ...action.meta.arg,
        channelEpgListCurrentVideo: action.payload.data
      };
      state.channelEpg.channelEpgDataView = {
        ...state.channelEpg.channelEpgDataView,
        ...action.meta.arg,
        channelEpgListView: action.payload.data
      };
    },
    [fetchChannelEpgAll.rejected]: (state) => {
      state.channelEpg.status = FETCH_STATUS_FAILED;
    },

    [updateChannelEpgCurrentVideo.pending]: (state) => {
      state.channelEpg.status = FETCH_STATUS_LOADING;
    },

    [updateChannelEpgCurrentVideo.fulfilled]: (state, action) => {
      state.channelEpg.status = FETCH_STATUS_SUCCESS;
      state.channelEpg.channelEpgDataCurrentVideo = {
        ...action.meta.arg,
        channelEpgListView: action.payload.data
      };
    },
    [updateChannelEpgCurrentVideo.rejected]: (state) => {
      state.channelEpg.status = FETCH_STATUS_FAILED;
    },

    [updateChannelEpgView.pending]: (state) => {
      state.channelEpg.channelEpgDataView.status = FETCH_STATUS_LOADING;
    },

    [updateChannelEpgView.fulfilled]: (state, action) => {
      state.channelEpg.channelEpgDataView = {
        ...action.meta.arg,
        channelEpgListView: action.payload.data
      };
      state.channelEpg.channelEpgDataView.status = FETCH_STATUS_SUCCESS;
    },
    [updateChannelEpgView.rejected]: (state) => {
      state.channelEpg.channelEpgDataView.status = FETCH_STATUS_FAILED;
    },
    [fetchFavoriteChannels.pending]: (state) => {
      state.channelsFavorites.status = FETCH_STATUS_LOADING;
    },
    [fetchFavoriteChannels.fulfilled]: (state, action) => {
      state.channelsFavorites.status = FETCH_STATUS_SUCCESS;
      state.channelsFavorites.channelsFavoritesList = action.payload.data;
    },
    [fetchFavoriteChannels.rejected]: (state) => {
      state.channelsFavorites.status = FETCH_STATUS_FAILED;
    },

    [updatefetchFavoriteChannels.fulfilled]: (state, action) => {
      state.channelsFavorites.channelsFavoritesList = action.payload.data;
    },
    [updatefetchFavoriteChannels.rejected]: (state) => {
      state.channelsFavorites.status = FETCH_STATUS_FAILED;
    },
    [updateFavoriteChannels.fulfilled]: (state, action) => {
      state.channelsFavorites.channelsFavoritesList = action.payload.data;
    },
    [addFavoriteChannel.pending]: (state) => {
      state.channelToFavoriteStatus = FETCH_STATUS_LOADING;
    },
    [addFavoriteChannel.fulfilled]: (state) => {
      state.channelToFavoriteStatus = FETCH_STATUS_SUCCESS;
    },
    [addFavoriteChannel.rejected]: (state) => {
      state.channelToFavoriteStatus = FETCH_STATUS_FAILED;
    },
    [removeFavoriteChannel.pending]: (state) => {
      state.channelToFavoriteStatus = FETCH_STATUS_LOADING;
    },
    [removeFavoriteChannel.fulfilled]: (state) => {
      state.channelToFavoriteStatus = FETCH_STATUS_SUCCESS;
      state.currentChannelInfo.channelInfo.briz_billing_product_info.in_favorites = false;
      state.channelsFavorites.channelsFavoritesList =
        state.channelsFavorites.channelsFavoritesList.filter(
          ({ id }) => id !== state.currentChannelInfo.channelInfo.id
        );
    },
    [removeFavoriteChannel.rejected]: (state) => {
      state.channelToFavoriteStatus = FETCH_STATUS_FAILED;
    }
  }
});

// Selectors
export const getChannelsStream = (state) => state.channels.stream;
export const getChannelsGroupedInfo = (state) => state.channels.channelsGrouped;
export const getChannelEpgInfo = (state) => state.channels.channelEpg;
export const getChannelEpgListCurrentVideo = (state) =>
  state.channels.channelEpg.channelEpgDataCurrentVideo.channelEpgListCurrentVideo;
export const getChannelsFavoritesInfo = (state) => state.channels.channelsFavorites;
export const getChannelToFavoriteStatus = (state) => state.channels.channelToFavoriteStatus;
export const getCurrentChannelInfo = (state) => state.channels.currentChannelInfo;
export const getUpdateChannelEpgViewStatus = (state) =>
  state.channels.channelEpg.channelEpgDataView.status;

export const {
  setChannelId,
  resetChannelStream,
  setInitialFavoritesChannels,
  setInitialChannelsGrouped,
  resetCurentChannelInfo,
  addToFavoriteChannelList,
  removeFromFavoriteChannelList,
  changeCurrentChannelInfoStatusFavorite,
  updateCurrentActiveChannelsGroup
} = channelsSlice.actions;

export default channelsSlice.reducer;
